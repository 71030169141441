import React from "react";
import Layout from "../../components/Layout/Layout";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Box } from "@material-ui/core";

const TuyenDung = () => {
  // const breakpointsXs = useMediaQuery("(max-width: 1000px)");

  const tuyenDungData = useStaticQuery(
    graphql`
      query getPage {
        allWpPage(filter: { uri: { eq: "/tuyen-dung/" } }) {
          nodes {
            content
            title
            uri
          }
        }
      }
    `
  );

  const data = tuyenDungData?.allWpPage?.nodes?.[0];

  console.log(data);

  if (!data) {
    navigate("/");
  }

  return (
    <Layout>
      <Box width="100%" maxWidth={1120} mx="auto" px={2} pt={6}>
        <Box color="#cf2e2e" mb={8} pb={3} borderBottom="2px solid">
          <Box fontSize={28} fontWeight={700}>
            CÔNG TY TRÁCH NHIỆM HỮU HẠN 2-9
          </Box>
          <Box fontSize={20}>
            Địa chỉ: Tổ dân phố Ngô Gia Khảm, P. Châu Sơn, TP. Phủ Lý, Hà Nam
            <br />
            Điện thoại: 02263.858.948
          </Box>
        </Box>
        <Box
          textAlign="center"
          fontSize={26}
          fontWeight={700}
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
        />
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            fontSize={20}
            my={3}
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default TuyenDung;
